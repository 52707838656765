import { queryOptions, type QueryClient, type QueryKey } from "@tanstack/react-query";
import type { BillingAccountInvoice } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { mutationOptions } from "../../utils/query/runMutation.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { toastOptions } from "../compute/vmQuery.ts";
import { raiseRequestToast } from "../notifications/toast.tsx";

export type Response = Map<BillingAccountInvoice["id"], BillingAccountInvoice>;

export interface Params {
    billing_account_id: number;
}

export function getQueryKey(params?: Params): QueryKey {
    return params == null ? ["invoice/list"] : ["invoice/list", params.billing_account_id];
}

export function getQuery(client: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { billing_account_id } = params;
            return mapFrom(
                getResponseData(
                    await client.GET("/payment/invoice/list", {
                        signal,
                        params: { query: { billing_account_id } },
                    }),
                ),
                (invoice) => invoice.id,
            );
        },
    });
}

export const baInvoiceQueryAtom = atomFamily((billing_account_id: number) =>
    atomFromStandardQueryOptions(getQuery, { billing_account_id }),
);

export function payInvoiceMutation(api: ApiClient, queryClient: QueryClient) {
    return mutationOptions({
        mutationKey: ["/payment/pay_invoice"],
        async mutationFn({ invoice_id }: { invoice_id: number }) {
            return getResponseData(
                await api.POST("/payment/pay_invoice", {
                    params: { query: { invoice_id } },
                }),
            );
        },
        async onSettled(_res, err) {
            raiseRequestToast(err, {
                ...toastOptions,
                success: "Invoice paid",
                error: "Error trying to pay invoice",
            });
            await queryClient.invalidateQueries({ queryKey: getQueryKey() });
        },
    });
}
